










import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import echarts from 'echarts';

@Component
export default class barEchartDate extends Vue {
	@Prop() echartData: any;
	
	// echart DOM 节点
	private chartDom: any = null;
	private myChart: any = null;

	private showAuto: boolean = false;

	// 接收定时器返回变量
	private intervalNum: number = null;

	// 柱状图每个柱子的颜色设置
	// private barColor: string[] = ['#009DFF', '#024FEB', '#773FF5', '#03EDC7', '#523BFF', '#DE942B', '#773FF5', '#FFF04B'];
	private barColor: string[] = ['#0899F3', '#064CE2', '#19DFCB', '#523BFF', '#E29331', '#6F42E5', '#0EA9E8', '#CED35A', '#F38887', '#F3CC08', '#039392', '#AB650B', '#C6C8C9'];

	// echart图表饼图数据
	private pieData: Array<T> = []
	
	// legend右边距离
	private legendRight: number | string = '6%';

	private legendHeight: number | string = '95%'

	// legend顶部距离
	private legendTop: number | string = 'center';

	// legend 图例超过多少个使用滚动效果
	private xLength: number = 18;

	// echart圆饼图的位置
	private seriesCenter: string[] = ['22%', '47%'];

	// echart圆饼图的大小
	private seriesRadius: number | string = '80%';

	// 详情跳转的页面路由
	private routerPath: string = ''

	// 饼图模式参数（配置不同的tooltip 和 图例）
	// 实时采集数量用 mode1
	// 惠普金融活体贷款用 mode2
	private pieMode: string = 'mode1'

	// 饼图名称
	private pieName: string = ''

	// 下面的三个参数是用来控制饼图的 动态效果 的
	// showInnerPercent 和 highLightPercent 是互斥的
	// 因为 highLightPercent 的优先级更高
	
	// 饼图在内部显示百分比配置
	private showInnerPercent: boolean = false;

	// 百分比在高亮的时候才会在内部显示百分比配置
	private highLightPercent: boolean = false;

	// 是否需要动态显示tooltips
	private autoTooltip: boolean = true;

	
	@Watch("echartData", { immediate: true, deep: true })
	private dataChange(): void {
		// console.log('------通过prop传入了数据：', this.echartData);
		const obj: any = JSON.parse(JSON.stringify(this.echartData));
		const keys = Object.keys(obj)

		// console.log('------key arr', keys);
		if (!keys.length) return

		keys.forEach(item => {
			this[item] = obj[item];
		})

		// 初始echart Dom结构
		if(!this.chartDom) {
			this.chartDom = this.$refs.container;
			this.myChart = echarts.init(this.chartDom);
		}

		this.setXlengthAndSize();

		// 设置echart图表数据
		this.initChart();
	}

	// 此柱状图组件对应
	mounted():void {
		this.setScale();
	}

	private needScale: boolean = false;
	private isFullScreen: boolean = false;
	private legendSize: number = 6;

	setScale() {
		let clientWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		if (clientWidth === 1280) {
			// console.log('我这边是1920的150%缩放比例，因此需要设置scale');
			this.needScale = true;
			this.seriesRadius = '75%';
		}

		this.isFullScreen = document.body.scrollHeight === window.screen.height && document.body.scrollWidth === window.screen.width
		// console.log(document.body.scrollHeight, window.screen.height, document.body.scrollWidth, window.screen.width)
	}
	
	fontSize(res){
		let clientWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		if (!clientWidth) return;
		let fontSize = 100 * (clientWidth / 1920);
		return res*fontSize;
	}

	goMore(): void {
		this.$router.push(this.routerPath);
	}

	beforeDestroy(): void {
		// console.log('-------组件销毁，删除各种定时器, 饼图');
		clearInterval(this.intervalNum);
	}

	setXlengthAndSize() {
		let _that = this;
		// 设置图例最大显示条数：模式二：

		let rate = this.routerPath ? 0.7 : 0.9;

		let chartHeight = this.$refs.container.offsetHeight;

		// 为了防止意外发生获取不到dom的高度，给了一个默认的处理方式
		if (!chartHeight) {
			errorHandle();
			return;
		};

		let fontsize = this.fontSize(0.18);
		
		// console.log(fontsize, chartHeight, chartHeight * rate);

		let final = Math.floor((chartHeight * rate) / fontsize);

		// console.log('当前屏幕最多放几个', final);

		this.legendSize = final;
		this.xLength = final * 2;


		
		// 现在改成防止意外发生的默认情况
		function errorHandle() {
			// 设置图例最大显示条数：模式一：
			// 设置最大图例数量和单排最大size
			
			// 缩放情况下，有详情（70%高度）：全屏是8个，非全屏是6个
			// 						没有详情（90%高度）：全屏是10个，非全屏是8个
			// 1920情况下：有详情：全屏是8个，非全屏是7个
			// 						没有详情：全屏是10个，非全屏是9个

			let size = 0;  // 一列最大能放下多少个图例, 默认为缩放情况的非全屏，有详情
			// console.log(_that.needScale);

			size = _that.needScale ? 6 : 7;

			let type = '';
			type += _that.needScale ? '1' : '0';
			type += _that.routerPath ? '1' : '0';
			type += _that.isFullScreen ? '1' : '0';

			switch (type) {
				case '111': size = 8; break;
				case '110': size = 6; break;
				case '101': size = 10; break;
				case '100': size = 8; break;
				case '011': size = 8; break;
				case '010': size = 7; break;
				case '001': size = 10; break;
				case '000': size = 9; break;
			}

			_that.legendSize = size;
			_that.xLength = size * 2;

			return
		}


		// console.log('type: ', type, 'size: ', size, this.pieData.length, this.xLength);
	}

	setLegendRight(length) {
		if (length > this.xLength) return '15%';		// 当数据超过2列最大能展示的，就往右偏移15%，因为这个时候是滚动模式，只有一列

		if (length <= this.legendSize) {
			return '20%';
		} else {
			return typeof this.legendRight === 'string' ? this.legendRight : this.fontSize(this.legendRight/100);
		}
	}

	// 初始化图表数据
	private initChart(): void {
		// // 在重新渲染图表之前，就必须把定时器停止了，不然后面的数据会因为定时器错乱
		clearInterval(this.intervalNum)

		// 把饼图的数据转换成key-value形式，让formatter使用
		const pieData = JSON.parse(JSON.stringify(this.pieData))
		const pieKey: any = {}
		pieData.forEach(item => {
			pieKey[item.name] = item.value;

			if(this.highLightPercent) {
				// 设置高亮单独显示inner的百分比
				item.label = {
					show: false,
					position: 'inside',
					color: '#fff',
					formatter:data => data.percent.toFixed(1)+"%"
				}
			}
		});

		// console.log('pieData data: ', pieData);

		// 之前的操作都是一系列数据的过滤设置
		let option;

		option = {
			color: this.barColor,
			title: {
			},
			tooltip: {
				trigger: 'item',
				formatter: value => {
					// console.log('pie tooltip', value)
					if (this.pieMode === 'mode1') {
						// 模式1的tooltip显示
						let str = '' + value.seriesName + '<br />';
						str += `${value.marker}&nbsp;${value.name}: ${value.value}头`
						return str
					} else if (this.pieMode === 'mode2') {
						// 模式2的tooltip显示
						let str = `${value.name}:&nbsp;${value.value}&nbsp;${value.pp || ''}<br />`;
						Object.keys(value.data.children).forEach(item => {
							str += `${item}:&nbsp;${value.data.children[item]}<br />`;
						});
						return str;
					} else if (this.pieMode === 'mode3') {
						return `占比：${value.percent}%`;
					}
				},
				// padding: [1, 1, 0, 0],
				textStyle: {
					lineHeight: this.pieMode === 'mode2' ? this.fontSize(0.16) : 'unset',
					height: this.fontSize(0.14),
					fontSize: this.fontSize(0.14),		// 鼠标悬浮显示文字字体大小
				},
			},
			legend: {
				zlevel: -1,
				orient: 'vertical',
				// 设置图例右边距离
				right: this.setLegendRight(pieData.length),
				type: pieData.length > this.xLength ? 'scroll' : 'plain',		// 图例太多，进行滚动
				pageIconSize: [this.fontSize(0.12), this.fontSize(0.06)],	// 滚动图表的大小设置
				// 设置图例顶部距离
				top: typeof this.legendTop === 'string' ? this.legendTop : this.fontSize(this.legendTop/100),
				width: '50%',
				height: this.routerPath ? '70%' : '90%',	// 有详情，图例高70%
				formatter: (value) => {
					if (this.pieMode === 'mode1') {
						return `${value}:  ${pieKey[value]}头`;
					} else {
						// return value;
						return  value.length > 7 ? value.slice(0, 6) + '...' : value;
					}
				},
				textStyle: {
					fontSize: this.fontSize(0.14),		// 图例文字大小
					color: 'white',
				},
				itemHeight: this.fontSize(0.12), // 修改icon图形大小
				itemWidth: this.fontSize(0.12),
				itemGap: this.fontSize((this.pieData.length < 5 ? 8 : 4) / 100), // 修改间距
			},
			series: [
				{
					name: this.pieName,
					// 设置饼图位置 center 属性
					center: this.seriesCenter,
					type: 'pie',
					// 设置饼图大小 radius 属性
					radius: this.seriesRadius,
					hoverOffset: this.fontSize(0.12),			// 高亮凸起的高度设置，4.8生效，5.x不生效
					// data: this.pieData,
					data: pieData,
					label: {
						show: this.showInnerPercent,
						// 这里还有一个扩展，可以单独让饼图的 某个部分模块 在内部显示，只需要在 数组某个需要显示的对象加上label属性就行了
						position: 'inside',		// 饼图上面显示百分比，需要 inside 和 下面的 formatter配合
						fontSize: this.fontSize(0.14),
						formatter: data => data.percent.toFixed(1)+"%",
					}
				}
			]
		};

		option && this.myChart.setOption(option);


		// if (this.showAuto) {
		if (true && !(pieData.length > this.xLength)) {
				// 设置自动轮播tooltip
			let _that = this;
			function auto() {
				// 设置定时器, 每隔2s轮播tooltip
				
				let index = 0; //播放所在下标
				_that.intervalNum = setInterval(function() {

					// console.log('我这边的定时器是', _that.routerPath, _that.intervalNum);
					for(let idx in _that.pieData)
						// 遍历饼图数据，取消所有图形的高亮效果
						_that.myChart.dispatchAction({
							type: 'downplay',
							seriesIndex: 0,
							dataIndex: idx
					});

					if (_that.autoTooltip) {
						// 这里是提示 tooltip
						_that.myChart.dispatchAction({
							type: 'showTip',
							seriesIndex: 0,
							dataIndex: index
						});
					}

					// 这里是高亮弹起
					_that.myChart.dispatchAction({
						type: 'highlight',
						seriesIndex: 0,
						dataIndex: index
					});

					if (_that.highLightPercent) {
						// 高亮的同时，在inner内部展示百分比
						// console.log('----------highLightPercent', option);
						// option.series[0].data.forEach(item => item.label.show = false);
						// option.series[0].data[index].label.show = true;
						pieData.forEach(item => item.label.show = false);
						pieData[index].label.show = true;
						option && _that.myChart.setOption(option);
					}

					index++;

					if(index >= _that.pieData.length) {
						index = 0;
					}
				}, 4000);
			}

			this.chartDom.onmouseover = function () {
				// 本来使用echarts的事件，但是它那个需要移动到有颜色的柱子上才会触发，这样不行
				// 需要显示tooltip 就触发，没办法，只能移入整个dom结构就触发
				// console.log('---------mouseover事件, 清除定时器, 编号: ', _that.intervalNum);
				clearInterval(_that.intervalNum)
				
				for(let idx in _that.pieData)
					// 遍历饼图数据，取消所有图形的高亮效果
					_that.myChart.dispatchAction({
						type: 'downplay',
						seriesIndex: 0,
						dataIndex: idx
				});

				
				if (_that.highLightPercent) {
					// 高亮的同时，在inner内部展示百分比
					// console.log('----------highLightPercent', option);
					option.series[0].data.forEach(item => item.label.show = false);
					option && _that.myChart.setOption(option);
				}
			}

			this.chartDom.onmouseout = function () {
				// console.log('---------mouseout事件, 打开定时器，自动轮播');
				auto()
			}
			auto();
		} else {
			// console.log('---------我这里是因为太多的原因，不需要进入auto事件，因此需要取消鼠标移出移出事件');
			this.chartDom.onmouseout = null;
			this.chartDom.onmouseover = null;
		}

		
		

	}
}
