

















  import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
  import AMapLoader from '@amap/amap-jsapi-loader';
  import '@amap/amap-jsapi-types';
  import { Axios } from '@/common/http/http';
  import {enmuGroupCode , ReturnCodeFlag} from '@/common/enumts'

  const colors = [ "#103aa5" ];
  const $ = window.$

  @Component
  export default class bigScreenMap extends Vue {
    @Prop() authorityAdcode: number;
    @Prop() curentAdcode: number;
    @Prop() authorityName: string;

    mapOptions: AMap.MapOptions = {
      zoom: 4.5,
      center: [116.412427, 39.303573],
      // mapStyle: 'amap://styles/darkblue', //设置地图的显示样式
      mapStyle: 'amap://styles/67d3ce96d3361d2e68f1f336e2583f1e', //设置地图的显示样式,阿里后台的自定义地图样式
    };
    map: Amap | null = null;
    AMap: AMap | null = null;
    axios: Axios = null;

    districtExplorer: any = null;   // 地图 区域对象

    currentAreaNode: any = null;    // 当前选中的区域 对象

    markerArr: Array<T> = [];   // 高德的 点位对象 数组
    // 打点标记的坐标
    markerList: Array<T> = [];  // 后台返回的地图点位信息数组

    // 点位悬浮信息载体对象
    frameDetail: any = { name: '', arr: [] }

    // 当前区域编码
    // 把高德的区域编码尾数的0去除了，全国是0
    groupCode: number = 0

    showMessage: boolean = false;   // 点位详情信息 悬浮框
    mbL: number = 0;    // messageBoxLeft 缩写： 悬浮框左定位
    mbT: number = 0;    // messageBoxTop  缩写： 悬浮框高定位

    areaObj: any = null;    // 操作地图后，对应那个区域的 数据对象

    private textMarkArr = [];   // 文本类型 的打点标记数组，用来存放、批量清除

    private settledArea = []; // 区/县级别的区域，用来点击到最后一层，显示地名

    private highLightAdcodeArr: (number | string)[] = [];   // 地图上需要高亮显示的区域

    // 是否是第一次加载，因为第一次加载，index.html的script标签加载的js还没有来得及完全加载
    // 所以第一次的时候，地图操作要延迟一段时间，等js加载完成
    private isInit: boolean = true;

    constructor() {
      super();
      this.axios = new Axios()
    }

    mounted() {
      // this.$nextTick(() => {
      //   // this.initData(0);
      // });
      this.initMap();
    }

    private hoverLeft: number = 0;
    private hoverTop: number = 0;
    private showDone: boolean = false;

		@Watch("showMessage", { immediate: true, deep: true })
    setHoverStyle(val) {
      // console.log('showMessage 的值变化了', val);
      if (val) {
        let dom = document.getElementsByClassName('show-message')[0];
        let fath = document.getElementsByClassName('page-full')[0];
        let that = this;

        setTimeout(() => {
          let left = 0;
          let top = 0;

          if (dom) {
            // console.log('点位偏移量', that.mbL, that.mbT)
            // console.log('悬浮框宽高', dom.clientWidth, dom.clientHeight);
            // console.log('地图容器宽高', fath.clientWidth, fath.clientHeight);

            let domWidth = dom.clientWidth;
            let domHeight = dom.clientHeight;
            let faWidth = fath.clientWidth;

            // 如果 高偏移量 大于 offsetHeight，则 top = mbt - offsetHeight，否则为 0
            if (that.mbT - domHeight > 0) {
              top = that.mbT - domHeight;
            } else {
              top = 0;
            }
            
            // 如果 左偏移量 mbl + offsetWidth 的一半，大于 地图的div宽度，则 left = div - offsetWidth
            // 如果 左偏移量 mbL ，大于 offsetWidth 的一半，则 left = mbl - (offsetWidth/2)

            if ((that.mbL + domWidth/2) > faWidth - 30) {
              left = faWidth - domWidth - 20;
            } else if (that.mbL < domWidth/2) {
              left = 0;
            } else {
              left = that.mbL - domWidth/2;
            }
          }

          // console.log('最终计算结果', left, top);
          that.hoverLeft = left;
          that.hoverTop = top;
          that.showDone = true;
        }, 0);
      }
    }

    // 重置高亮显示区域
    refreshHight() {
      // console.log('-------highLightAdcodeArr', this.highLightAdcodeArr);
      if (this.highLightAdcodeArr.length) {
        this.highLightAdcodeArr.forEach(item => {
          let temm = this.districtExplorer.findFeaturePolygonsByAdcode(item);

          for (let i = 0, len = temm.length; i < len; i++) {
            // console.log('------------ 更新了几个地方', i);
            // 设置悬浮所在的区域的样式
            temm[i].setOptions({
              fillOpacity: 1,
              fillColor: '#509fed', //填充色
            });
          }
        })
      }
    }

    // 根据当前点击区域的adcode，获取它下面的所有子节点
    // 在子节点的 坐标位置 上标注城市名称（因为目前地图功能，不会显示对应的城市名）
    private getAreaAllCode(subFeatures: any):void {
      // console.log('-------来进入打点', subFeatures);
      // 先清除城市标记
      if (this.map) {
        this.map.remove(this.textMarkArr);
        this.textMarkArr = [];
      }

      if (!subFeatures.length) {
        // 表明是区县级别，没有下属了
        subFeatures.push({
          properties: this.settledArea,
          isSet: true,
        })

        if (this.isLastLevel) {
          subFeatures[0].properties.name = this.lastLevelObj.name;
          subFeatures[0].properties.center = [this.lastLevelObj.longitude, this.lastLevelObj.latitude];
          this.isLastLevel = false;
        }
        // console.log('我进来了', subFeatures)
      }

      subFeatures.forEach(item => {
        // console.log('进行地图文本文字的打点', item)
        // 创建纯文本标记
        var text = new AMap.Text({
          text: item.properties.name,
          anchor:'center', // 设置文本标记锚点
          draggable:true,
          style:{
            'transform': 'scale(0.8)',
            'border': 'unset',
            'background-color': 'transparent',
            'font-size': '0.1rem',
            'color': 'white',
          },
          extData: item,
          position: [item.properties.center ? item.properties.center[0] : 0,item.properties.center? item.properties.center[1] : 0]
        });

        text.on('click', (item) => {
          // console.log(item, item.target.getExtData());
          let target = item.target.getExtData();
          // console.log('target: ', target);
          if (!target.properties.childrenNum) {
            this.settledArea = target.properties;
          }
          if (!target.hasOwnProperty('isSet')) {
            this.switch2AreaNode(item.target.getExtData().properties.adcode);
            this.$emit('setCurentAreaName', item.target.getExtData().properties.name);
          // 进行地图的进入
          }
        })

        this.textMarkArr.push(text);
        text.setMap(this.map);
      })
    }

    // 父组件点击树结构的最后一层级，地图是无法通过code获取到区域对象的，所以无法获取名称和点位信息
    private lastLevelObj = {
      name: '',
      longitude: 0,
      latitude: 0,
    }

    private isLastLevel: boolean = false; //当前点击的是最后一层级的节点

    setLastLevelObj(name, longitude, latitude) {
      this.lastLevelObj = {
        name,
        longitude,
        latitude
      };
      this.isLastLevel = true;
    }

    // 初始化地图
    initMap() {
      window._AMapSecurityConfig = { securityJsCode: '69c351b81e6df1d13fde026d56e2da78' };
      AMapLoader.load({
        'key': '935a50506de9178c94c3fcb685910705',  // 申请好的Web端开发者Key，首次调用 load 时必填
        'version': '2.0',          // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        'plugins': [],             // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        'AMapUI': {                // 是否加载 AMapUI，缺省不加载
          'version': '1.1',        // AMapUI 版本
          'plugins': ['geo/DistrictExplorer'],      // 需要加载的 AMapUI ui插件
        },
        'Loca': {                  // 是否加载 Loca， 缺省不加载
          'version': '2.0'         // Loca 版本
        },
      }).then((AMap, ...args) => {
        // 初始话地图对象
        this.AMap = AMap;
        this.map = new AMap.Map('container', this.mapOptions);
        this.initPage(window.AMapUI.DistrictExplorer);
        
      }).catch(e => {
        console.log(e);
      });
    }

    // 获取地图点位信息
    initData(groupCode) {
      const obj = { groupCode };

      // 先清除所有点位信息
      this.clearMark()
      this.axios.get('/animal/web/bigScreenGovernmentNew2/getSysFarmMap', obj, false)
        .then((res: any) => {
          // console.log('----- 获取了地图点位信息: ', res);
          let data = JSON.parse(JSON.stringify(res.data));
          // console.log(data)
          data =[
            {
                "groupCode": "5201",
                "name": "贵阳市",
                "longitude": "106.628201",
                "latitude": "26.646694",
                "sysFarmMapDtos": [
                    {
                        "num": 5142,
                        "typeId": "607970402957787136",
                        "typeName": "基础母牛",
                        "groupCode": "520327101"
                    },
                    {
                        "num": 784,
                        "typeId": "607970402957787136",
                        "typeName": "后备母牛",
                        "groupCode": "520327101"
                    },
                    {
                        "num": 810,
                        "typeId": "607970402957787136",
                        "typeName": "大育成牛",
                        "groupCode": "520327101"
                    },
                    {
                        "num": 1045,
                        "typeId": "703349364482899968",
                        "typeName": "架子牛",
                        "groupCode": "520327101"
                    },
                    {
                        "num": 1246,
                        "typeId": "101",
                        "typeName": "育肥牛",
                        "groupCode": "520327100"
                    },
                    {
                        "num": 542,
                        "typeId": "101",
                        "typeName": "青年牛",
                        "groupCode": "520327100"
                    },
                    {
                        "num": 2540,
                        "typeId": "703349250402025472",
                        "typeName": "犊牛",
                        "groupCode": "520329109"
                    }
                ]
            },
            {
                "groupCode": "5202",
                "name": "六盘水市",
                "longitude": "104.830357",
                "latitude": "26.592538",
                "sysFarmMapDtos": [
                    {
                        "num": 6105,
                        "typeId": "607970402957787136",
                        "typeName": "基础母牛",
                        "groupCode": "520327101"
                    },
                    {
                        "num": 912,
                        "typeId": "607970402957787136",
                        "typeName": "后备母牛",
                        "groupCode": "520327101"
                    },
                    {
                        "num": 789,
                        "typeId": "607970402957787136",
                        "typeName": "大育成牛",
                        "groupCode": "520327101"
                    },
                    {
                        "num": 1560,
                        "typeId": "703349364482899968",
                        "typeName": "架子牛",
                        "groupCode": "520327101"
                    },
                    {
                        "num": 1541,
                        "typeId": "101",
                        "typeName": "育肥牛",
                        "groupCode": "520327100"
                    },
                    {
                        "num": 641,
                        "typeId": "101",
                        "typeName": "青年牛",
                        "groupCode": "520327100"
                    },
                    {
                        "num": 2488,
                        "typeId": "703349250402025472",
                        "typeName": "犊牛",
                        "groupCode": "520329109"
                    }
                ]
            },
            {
                "groupCode": "5203",
                "name": "遵义市",
                "longitude": "106.937265",
                "latitude": "27.706626",
                "sysFarmMapDtos": [
                    {
                        "num": 15455,
                        "typeId": "607970402957787136",
                        "typeName": "基础母牛",
                        "groupCode": "520327101"
                    },
                    {
                        "num": 2142,
                        "typeId": "607970402957787136",
                        "typeName": "后备母牛",
                        "groupCode": "520327101"
                    },
                    {
                        "num": 1501,
                        "typeId": "607970402957787136",
                        "typeName": "大育成牛",
                        "groupCode": "520327101"
                    },
                    {
                        "num": 2512,
                        "typeId": "703349364482899968",
                        "typeName": "架子牛",
                        "groupCode": "520327101"
                    },
                    {
                        "num": 3540,
                        "typeId": "101",
                        "typeName": "育肥牛",
                        "groupCode": "520327100"
                    },
                    {
                        "num": 1840,
                        "typeId": "101",
                        "typeName": "青年牛",
                        "groupCode": "520327100"
                    },
                    {
                        "num": 8585,
                        "typeId": "703349250402025472",
                        "typeName": "犊牛",
                        "groupCode": "520329109"
                    }
                ]
            },
            {
                "groupCode": "5204",
                "name": "安顺市",
                "longitude": "105.932188",
                "latitude": "26.245544",
                "sysFarmMapDtos": [
                    {
                        "num": 3104,
                        "typeId": "607970402957787136",
                        "typeName": "基础母牛",
                        "groupCode": "520327101"
                    },
                    {
                        "num": 574,
                        "typeId": "607970402957787136",
                        "typeName": "后备母牛",
                        "groupCode": "520327101"
                    },
                    {
                        "num": 578,
                        "typeId": "607970402957787136",
                        "typeName": "大育成牛",
                        "groupCode": "520327101"
                    },
                    {
                        "num": 1451,
                        "typeId": "703349364482899968",
                        "typeName": "架子牛",
                        "groupCode": "520327101"
                    },
                    {
                        "num": 1451,
                        "typeId": "101",
                        "typeName": "育肥牛",
                        "groupCode": "520327100"
                    },
                    {
                        "num": 254,
                        "typeId": "101",
                        "typeName": "青年牛",
                        "groupCode": "520327100"
                    },
                    {
                        "num": 2840,
                        "typeId": "703349250402025472",
                        "typeName": "犊牛",
                        "groupCode": "520329109"
                    }
                ]
            },
            {
                "groupCode": "5205",
                "name": "毕节市",
                "longitude": "105.291544",
                "latitude": "27.283615",
                "sysFarmMapDtos": [
                    {
                        "num": 1582,
                        "typeId": "607970402957787136",
                        "typeName": "基础母牛",
                        "groupCode": "520327101"
                    },
                    {
                        "num": 579,
                        "typeId": "607970402957787136",
                        "typeName": "后备母牛",
                        "groupCode": "520327101"
                    },
                    {
                        "num": 454,
                        "typeId": "607970402957787136",
                        "typeName": "大育成牛",
                        "groupCode": "520327101"
                    },
                    {
                        "num": 1856,
                        "typeId": "703349364482899968",
                        "typeName": "架子牛",
                        "groupCode": "520327101"
                    },
                    {
                        "num": 2504,
                        "typeId": "101",
                        "typeName": "育肥牛",
                        "groupCode": "520327100"
                    },
                    {
                        "num": 1054,
                        "typeId": "101",
                        "typeName": "青年牛",
                        "groupCode": "520327100"
                    },
                    {
                        "num": 1540,
                        "typeId": "703349250402025472",
                        "typeName": "犊牛",
                        "groupCode": "520329109"
                    }
                ]
            },
            {
                "groupCode": "5206",
                "name": "铜仁市",
                "longitude": "109.189528",
                "latitude": "27.731555",
                "sysFarmMapDtos": [
                    {
                        "num": 3154,
                        "typeId": "607970402957787136",
                        "typeName": "基础母牛",
                        "groupCode": "520327101"
                    },
                    {
                        "num": 645,
                        "typeId": "607970402957787136",
                        "typeName": "后备母牛",
                        "groupCode": "520327101"
                    },
                    {
                        "num": 841,
                        "typeId": "607970402957787136",
                        "typeName": "大育成牛",
                        "groupCode": "520327101"
                    },
                    {
                        "num": 1231,
                        "typeId": "703349364482899968",
                        "typeName": "架子牛",
                        "groupCode": "520327101"
                    },
                    {
                        "num": 2709,
                        "typeId": "101",
                        "typeName": "育肥牛",
                        "groupCode": "520327100"
                    },
                    {
                        "num": 1421,
                        "typeId": "101",
                        "typeName": "青年牛",
                        "groupCode": "520327100"
                    },
                    {
                        "num": 3655,
                        "typeId": "703349250402025472",
                        "typeName": "犊牛",
                        "groupCode": "520329109"
                    }
                ]
            },
            {
                "groupCode": "5223",
                "name": "黔西南",
                "longitude": "104.906419",
                "latitude": "25.087733",
                "sysFarmMapDtos": [
                    {
                        "num": 6142,
                        "typeId": "607970402957787136",
                        "typeName": "基础母牛",
                        "groupCode": "520327101"
                    },
                    {
                        "num": 1145,
                        "typeId": "607970402957787136",
                        "typeName": "后备母牛",
                        "groupCode": "520327101"
                    },
                    {
                        "num": 154,
                        "typeId": "607970402957787136",
                        "typeName": "大育成牛",
                        "groupCode": "520327101"
                    },
                    {
                        "num": 1815,
                        "typeId": "703349364482899968",
                        "typeName": "架子牛",
                        "groupCode": "520327101"
                    },
                    {
                        "num": 2594,
                        "typeId": "101",
                        "typeName": "育肥牛",
                        "groupCode": "520327100"
                    },
                    {
                        "num": 945,
                        "typeId": "101",
                        "typeName": "青年牛",
                        "groupCode": "520327100"
                    },
                    {
                        "num": 3564,
                        "typeId": "703349250402025472",
                        "typeName": "犊牛",
                        "groupCode": "520329109"
                    }
                ]
            },
            {
                "groupCode": "5226",
                "name": "黔东南",
                "longitude": "107.977488",
                "latitude": "26.583352",
                "sysFarmMapDtos": [
                    {
                        "num": 5872,
                        "typeId": "607970402957787136",
                        "typeName": "基础母牛",
                        "groupCode": "520327101"
                    },
                    {
                        "num": 1284,
                        "typeId": "607970402957787136",
                        "typeName": "后备母牛",
                        "groupCode": "520327101"
                    },
                    {
                        "num": 474,
                        "typeId": "607970402957787136",
                        "typeName": "大育成牛",
                        "groupCode": "520327101"
                    },
                    {
                        "num": 1655,
                        "typeId": "703349364482899968",
                        "typeName": "架子牛",
                        "groupCode": "520327101"
                    },
                    {
                        "num": 3017,
                        "typeId": "101",
                        "typeName": "育肥牛",
                        "groupCode": "520327100"
                    },
                    {
                        "num": 1459,
                        "typeId": "101",
                        "typeName": "青年牛",
                        "groupCode": "520327100"
                    },
                    {
                        "num": 3840,
                        "typeId": "703349250402025472",
                        "typeName": "犊牛",
                        "groupCode": "520329109"
                    }
                ]
            },
            {
                "groupCode": "5227",
                "name": "黔南",
                "longitude": "107.601263",
                "latitude": "26.171073",
                "sysFarmMapDtos": [
                    {
                        "num": 6587,
                        "typeId": "607970402957787136",
                        "typeName": "基础母牛",
                        "groupCode": "520327101"
                    },
                    {
                        "num": 1097,
                        "typeId": "607970402957787136",
                        "typeName": "后备母牛",
                        "groupCode": "520327101"
                    },
                    {
                        "num": 545,
                        "typeId": "607970402957787136",
                        "typeName": "大育成牛",
                        "groupCode": "520327101"
                    },
                    {
                        "num": 1751,
                        "typeId": "703349364482899968",
                        "typeName": "架子牛",
                        "groupCode": "520327101"
                    },
                    {
                        "num": 2971,
                        "typeId": "101",
                        "typeName": "育肥牛",
                        "groupCode": "520327100"
                    },
                    {
                        "num": 1785,
                        "typeId": "101",
                        "typeName": "青年牛",
                        "groupCode": "520327100"
                    },
                    {
                        "num": 3250,
                        "typeId": "703349250402025472",
                        "typeName": "犊牛",
                        "groupCode": "520329109"
                    }
                ]
            }
          ]

          // 过滤一下，把没有牛的数据排除掉
          // data = data.filter(item => {
          //   let arr = item.sysFarmMapDtos.filter(item => item.typeName.includes('牛'));
          //   return Boolean(arr.length);
          // })

          this.markerList = data;
          let arr = [];
          data.forEach(item => {
            // console.log('打点信息给我, 来设置高亮区域', item)
            let id = item.groupCode;
            
            // 补齐六位
            for (let i = id.length; i < 6; i += 1) {
              id += '0';
            }
            arr.push(Number(id));
          })
          
          // 记录需要高亮显示的区域
          this.highLightAdcodeArr = arr;
          
          // 获取数据后进行地图打点
          // let _that = this;

          // if (this.isInit) { // 因为现在在父组件延迟等地图加载完成了
          //   // 第一次进入页面，需要延迟加载，避免地图对象不在
          //   setTimeout(() => {
          //     _that.addMark();
          //     _that.refreshHight();
          //   }, 3000);
          //   this.isInit = false;
          // } else {
          //   this.addMark();
          //   this.refreshHight();
          // }
          
          this.addMark();
          this.refreshHight();
          this.isInit = false
        }).catch(err => {
          console.log(err)
        });
    }

    // 地图打点
    addMark(): void {
      // 遍历后台返回的打点信息 数组
      this.markerList.forEach(item => {
        const marker = new AMap.LabelMarker({
          icon: {
            image: "http://marktrace-cattle.oss-cn-hangzhou.aliyuncs.com/marktrace-cattle/test/cattle/animal/h5/700701068383752192/2022-05-23/700701068383752192/%E7%9F%A2%E9%87%8F%E6%99%BA%E8%83%BD%E5%AF%B9%E8%B1%A1%20%E6%8B%B7%E8%B4%9D%203.png",
            size: [16, 16]
          },
          position: [Number(item.longitude), Number(item.latitude)],
          extData: item
          // offset: new AMap.Pixel(-13, -40)
        });

        // 添加点位的 鼠标悬浮事件
        marker.on('mouseover', this.pointHover);
        marker.on('mouseout', this.pointOut);
        
        this.map.add(marker);         // 打点
        this.markerArr.push(marker);  // 记录这些点位的信息，以便后面清除，重新渲染
      });
      
      this.markerList = [];
    }

    // 地图点位鼠标悬浮移入事件
    pointHover(e: any) {
      this.showMessage = true
      this.showDone = false;
      this.mbL = e.pixel.x
      this.mbT = e.pixel.y
      // console.log(e.pixel.x, e.pixel.y, e)
      // console.log('getExtData ', e.target.getExtData())
      let temp = e.target.getExtData()
      let obj = {
        name: temp.name,
        // arr: temp.sysFarmMapDtos.filter(item => item.typeName.includes('牛'))
        arr: temp.sysFarmMapDtos
      }
      this.frameDetail = obj;
      // console.log('打点标记详细页面：', this.frameDetail)
      // console.log('鼠标移入')
    }


    

    pointOut(e:any) {
      this.showMessage = false;
      // console.log('鼠标移出')
    }

    // 清除点位标记
    clearMark(): void {
      if (this.map) {
        this.map.remove(this.markerArr);
        this.markerArr = [];
      }
    }

    // 判断当前用户是否有权限进行地图点击
    checkAuthority(adcode: number): boolean {
      let auth = this.authorityAdcode;
      if (auth === 0) return true;      // 如果权限是全国，可以点击
      if (adcode.toString().indexOf(auth.toString()) === 0) return true;  // 如果权限是当前子区域，可以点击
      return false
    }

    // 接口获取打点坐标
    interToMark(areaNode: any) {
      // console.log('-------地图有变化: ', areaNode)
      if (!this.areaObj) return;

      // 高德地图默认全国是100000，需要转成后台对应的0
      let num = areaNode.adcode;
      // 去除编码末尾的0
      let codeObj:any =  ReturnCodeFlag(enmuGroupCode , areaNode.adcode)
      console.log(codeObj)
      if(codeObj.flag){
        num = codeObj.id
      }else{
        num = Number(num.toString().replace(/(0+)\b/gi,""));
      }
      console.log(num)

      // emit，告诉父组件我点击了那个地区，让父组件更新echarts图表数据
      // console.log('num', num)
      this.$emit('setCurentAdcode', num);

      this.initData(num);
    }

    // 各种点击事件设置
    initPage(DistrictExplorer: any) {
      //创建一个实例
      const districtExplorer = this.districtExplorer = new DistrictExplorer({
        eventSupport: true, //打开事件支持
        map: this.map
      });

      // 鼠标移动到地图区域上面的 回调事件 hover
      const toggleHoverFeature = (feature: any, isHover: any, position: any) => {
        if (!feature) {
          return;
        }

        var props = feature.properties;

        //更新相关多边形的样式
        const polys = districtExplorer.findFeaturePolygonsByAdcode(props.adcode);
        // 鼠标悬停到地图地图界面上的区域信息
        // console.log('polys -- data: ', polys);
        for (let i = 0, len = polys.length; i < len; i++) {
          // console.log('------------更新了几个地方', i);
          // 设置悬浮所在的区域的样式
          polys[i].setOptions({
            fillOpacity: isHover ? 1 : 1,
            fillColor:isHover ? '#509fed' : '#0e3fb9', //填充色
          });
        }
        // console.log('看看有没有移动到高亮的地方', this.highLightAdcodeArr, props.adcode);
        if (this.highLightAdcodeArr.includes(props.adcode)) {
          // console.log('我这边悬浮到了高亮显示的位置，但是我移出之后，不能熄灭他');
          this.refreshHight();
        }
      }

      // //监听feature的hover事件
      districtExplorer.on('featureMouseout featureMouseover', (e: any, feature: any) => {
        toggleHoverFeature(feature, e.type === 'featureMouseover',
            e.originalEvent ? e.originalEvent.lnglat : null);
      });

      //feature被点击
      districtExplorer.on('featureClick', (e: any, feature: any) => {
        // console.log('----------featureClick feature data: ', feature)
        // console.log('我点击了内部区域，当前账户的地图权限：', this.authorityAdcode, '现在点击的区域编码', feature);
        if (!this.checkAuthority(feature.properties.adcode)) return;

        var props = feature.properties;

        // 告诉父组件，当前区域的 地区名称
        // console.log('---------setCurentAreaName', props.name, props);
        if (!props.childrenNum) {
          // 表明没有child了，到底了，要填充一个名字
          this.settledArea = props;
          // console.log('-------settledArea', props)
        }
        this.$emit('setCurentAreaName', props.name);
        this.areaObj = props;

        // 绘制点击的区域
        this.switch2AreaNode(props.adcode);
      });

      //外部区域被点击
      districtExplorer.on('outsideClick', (e: any) => {
        // console.log('----------outsideClick outside data: ', e)
        districtExplorer.locatePosition(e.originalEvent.lnglat, (error: any, routeFeatures: any) => {
          // console.log('隔壁省市的编码routeFeatures： ', routeFeatures)

            console.log(routeFeatures)
          if (routeFeatures && routeFeatures.length > 1) {
            //切换到省级区域

            let code = routeFeatures[1].properties.adcode;
            let name = routeFeatures[1].properties.name;
            
            // console.log('我点击了外部区域，当前账户的地图权限：', this.authorityAdcode,
            //   '点击的区域编码', Number(code.toString().replace(/(0+)\b/gi,"")));
            if (this.authorityAdcode === 0) {
              
              // console.log('我的权限是全国, 不用判断');
            } else {
              // console.log('我的权限不是全国，点击外部只能回退到最大的权限');
              if (this.authorityAdcode === this.curentAdcode) return;

              // console.log('-----开始')

              let id = this.authorityAdcode;
              for (let i = Number(id).toString().length; i < 6; i += 1) {
                id += '0';
              }
              code = id;
              name = this.authorityName;
              // if (!this.checkAuthority(Number(code.toString().replace(/(0+)\b/gi,"")))) return;
            }


            this.switch2AreaNode(code);
            this.$emit('setCurentAreaName', name);
          } else {
            //切换到全国
            if (!this.checkAuthority(100000)) return;
            this.switch2AreaNode(100000);
            this.$emit('setCurentAreaName', '中国');
          }
        }, {
          levelLimit: 2
        });
      });
      if (!this.checkAuthority(100000)) return;
      this.switch2AreaNode(100000);
    }

    //切换区域后刷新显示内容
    refreshAreaNode(areaNode: any) {
      this.districtExplorer.setHoverFeature(null);
      this.renderAreaPolygons(areaNode);
      
      var subFeatures = areaNode.getSubFeatures();
      // console.log('---------根据当前adcode获取子节点', subFeatures)
      this.getAreaAllCode(subFeatures)
      // 点击加载数据
      this.interToMark(areaNode)
    }

    //加载区域
    loadAreaNode(adcode: number, callback: Function) {
      this.districtExplorer.loadAreaNode(adcode, (error: any, areaNode: number) => {
        if (error) {
          if (callback) {
            callback(error);
          }
          console.error(error);
          return;
        }

        // this.renderAreaPanel(areaNode);
        // console.log('加载区域----', areaNode)

        if (callback) {
          callback(null, areaNode);
        }
      });
    }

    //绘制某个区域的边界
    renderAreaPolygons(areaNode: any) {
      //更新地图视野
      this.map.setBounds(areaNode.getBounds(), null, [15, 15, 15, 15], true);

      //清除已有的绘制内容
      this.districtExplorer.clearFeaturePolygons();
      // console.log('-----------绘制区域填充效果', areaNode);

      //绘制子区域
      this.districtExplorer.renderSubFeatures(areaNode, function (feature: any, i: any) {
        const fillColor = colors[i % colors.length];
        const strokeColor = colors[colors.length - 1 - i % colors.length];

        return {
          cursor: 'default',
          bubble: true,
          // strokeColor: strokeColor, //线颜色
          strokeColor: '#62e0fa', //线颜色
          strokeOpacity: 1, //线透明度
          strokeWeight: 1.2, //线宽
          fillColor: '0e3fb9', //填充色
          fillOpacity: 1, //填充透明度
        };
      });

      //绘制父区域
      this.districtExplorer.renderParentFeature(areaNode, {
        cursor: 'default',
        bubble: true,
        strokeColor: '#80D9F8', //线颜色
        strokeOpacity: 1, //线透明度
        strokeWeight: 1, //线宽
        fillColor: areaNode.getSubFeatures().length ? null : colors[0], //填充色
        fillOpacity: 1, //填充透明度
      });
    }

    //切换区域
    switch2AreaNode(adcode: number, callback?: Function, out?: boolean) {
      // 判断是否由父组件点击
      if (out) {
        this.areaObj = { adcode };
      }
      console.log('进行了区域的切换', adcode);

      if (this.currentAreaNode && ('' + this.currentAreaNode.getAdcode() === '' + adcode) && !this.isInit) {
        console.log('this.currentAreaNode', this.currentAreaNode.getAdcode());
        return;
      }
      console.log('00000000000')

      this.loadAreaNode(adcode, (error: any, areaNode: any) => {
        if (error) {
          if (callback) {
            callback(error);
          }
          return;
        }

        this.currentAreaNode = areaNode;

        //设置当前使用的定位用节点
        this.districtExplorer.setAreaNodesForLocating([this.currentAreaNode]);

        this.refreshAreaNode(areaNode);

        if (callback) {
          callback(null, areaNode);
        }
      });
    }
  }

