


















import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import echarts from 'echarts';

@Component
export default class barEchartDate extends Vue {
	@Prop() echartData: any;

	// 此柱状图表的一些控制显示参数说明：
	// 本月|本周： 由changeDate控制，如果有日期切换，则图表距离顶部的高度为40（为日期切换栏位留出空间）
	// 详情：	由 routerPath 控制，如果传入了 路由参数，则legend图例的高度设为15（为按钮留出空间）
	// 单位(户/千户/万户)：根据数据的最大值来设置 setRate 函数相关

	// div容器的class，因为组件复用，所以需要随机每个都不同
	private className: number = Math.random();

	// 柱状图模式
	private barMode: string = 'mode2';

	// 是否是第一次初始化
	private firstSetTime: boolean = true;

	// 是否启动自动轮播
	private autoPlay: boolean = true;

	// 日期选择  本月/本周
	private selectType: string = 'month';

	// 是否有日期切换: 用来控制顶部 本周/本月 的切换
	private changeDate: boolean = false;

	// 详情跳转的页面路由：也可用来判断是否显示详情按钮
	private routerPath: string = '';

	// legend定位 设置
	private legendTop: number = 0;
	private legendRight: number = 0;

	// legend宽度设置，图例文本超过了就会换行显示
	private legendWidth: string | number = '70%'

	// 图表高度设置
	private gridTop: number = 0;

	// 柱状图柱子宽度
	private barWidth: number = 12;

	// 接收定时器返回变量
	private intervalNumId: number = null;
	
	// 接收定时器返回变量
	private intervalNumArr: number[] = [];

	// 初始化延迟开始加载定时器的时间(为了错开所有的图表同步切换，异步切换效果更好)
	private delayTime: number = 0;

	// echart图表横坐标数据
	private xData: Array<T> = [];
	// private xData: [] = [];

	// 控制是否显示legend
	private showLegend: boolean = true;

	// y轴单位：户|头
	private yName: string = '头';

	// 是否需要初始化定时器（因为鼠标移出后，初始化位置）
	private needInitInterval: boolean = false;

	// 当前状态下，是否停止定时器（因为鼠标移入移出，清除定时器有时interval还没生成）
	// 所以在鼠标移入的时候，设置状态，然后在interval里面判断一下
	private stopInterval: boolean = false

	// 数据模板格式
	private echartDataObj: any = {
		'month': [
			// ['category', '基础母牛', '架子牛', '肉牛', '犊牛'],
			// ['扎鲁特旗', 421,130,633,730],
			// ['科尔沁左翼中旗', 623,123,348,328],
			// ['奈曼旗', 483, 273, 125, 934],
			// ['科尔沁区', 783, 125, 78, 48],
			// ['左翼后旗', 356, 222, 378, 44],
			// ['扎鲁', 42, 630, 62, 7],
			// ['科左翼中旗', 23, 23, 341, 38],
			// ['奈旗', 188, 33, 551, 0],
			// ['科沁区', 313, 0, 78, 47],
			// ['左旗', 439, 138, 148, 18]
		],
		'week': [
			// ['category', '基础母牛', '架子牛', '肉牛', '犊牛'],
			// ['扎鲁特旗', 21,13,63,3],
			// ['科尔沁左翼中旗', 23,13,38,32],
			// ['奈曼旗', 3, 23, 125, 34],
			// ['科尔沁区', 83, 25, 78, 4],
			// ['左翼后旗', 56, 22, 78, 4],
			// ['扎鲁', 42, 30, 62, 1],
			// ['科左翼中旗', 23, 23, 1, 38],
			// ['奈旗', 8, 33, 1, 0],
			// ['科沁区', 13, 0, 8, 47],
			// ['左旗', 39, 18, 18, 18]
		]
	}

	// 默认图标显示几个柱状指标
	private startValue: number = 0
	private endValue: number = 4

	// 柱状图每个柱子的颜色设置
	private barColor: string[] = ['#A772FA', '#523BFF', '#34DEF7', '#CE9E4D', '#024FEB', '#0EA9E8'];

	// 控制鼠标操作echart的时候，点击切换月/周，不要自动轮播
	private needAuto: boolean = true;

	// 点击日期，设置属性，重新获取数据
	selectDate(val: string): void {
		// console.log('----------选择时间', val);
		if (this.selectType === val) return;
		this.selectType = val;
		this.needAuto = false;
		this.initChart();
	}

	// 跳转详情页面
	goMore(): void {
		this.$router.push(this.routerPath);
	}
	
	private chartDom: any = null;
	private myChart: any = null;
	private box: any = null;

	
	@Watch("echartData", { immediate: true, deep: true })
	private dataChange(): void {
		// console.log('------通过prop传入了数据：', this.echartData);
		const obj: any = JSON.parse(JSON.stringify(this.echartData));
		const keys = Object.keys(obj)

		// console.log('------key arr', keys);
		if (!keys.length) return

		keys.forEach(item => {
			this[item] = obj[item];
		})

		// 初始echart Dom结构
		if(!this.chartDom) {
			this.chartDom = this.$refs.container;
			this.myChart = echarts.init(this.chartDom);
		}

		// 初始化了数据，firstSetTime 也要初始化，因为定时器引用了老的数据，不然会展示之前错误的数据
		// this.firstSetTime = true

		// 设置echart图表数据
		this.initChart();
	}

	// 此柱状图组件对应
	mounted():void {
		this.setScale();
		// console.log('我的电脑分辨率宽度', window.innerWidth, document.documentElement.clientWidth,document.body.clientWidth)
	}

	private needScale: boolean = false;

	setScale() {
		let clientWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		if (clientWidth === 1280) {
			// console.log('我这边是1920的150%缩放比例，因此需要设置scale');
			this.needScale = true;
		}
	}

	fontSize(res){
		let clientWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		if (!clientWidth) return;
		let fontSize = 100 * (clientWidth / 1920);
		return res*fontSize;
	}

	clearInterFunc(): void {
		// 有时候滑动太快了,会导致定时器清除失败,会累计很多个定时器存在内存中
		// 因此,通过数组来存放定时器,然后,每次清除,把所有的定时器遍历循环清除
		// 这个原因是我用了 setTimeout delay 启动循环器
		this.intervalNumArr.forEach(item => {
			clearInterval(item);
		});
		this.intervalNumArr = [];
	}

	checkInteFunc(): void {
		// 每次执行定时器的时候,再次确认一下之前的定时器是否清除掉了
		// 只留下最后一个定时器就行
		let len = this.intervalNumArr.length;
		if (len > 1) {
			for (let i = 0; i < len - 1; i += 1) {
				clearInterval(this.intervalNumArr[i]);
			}
			this.intervalNumArr = [this.intervalNumArr.pop()];
		}
	}

	beforeDestroy(): void {
		// console.log('-------组件销毁，删除各种定时器 柱状轮播图');
		// clearInterval(this.intervalNumId);
		this.clearInterFunc();
	}

	// 用来存放监听的鼠标移入移出事件
	private enterFunc: any = null;
	private leaveFunc: any = null;

	private initChart(): void {
		// 在重新渲染图表之前，就必须把定时器停止了，不然后面的数据会因为定时器错乱
		// clearInterval(this.intervalNumId);
		this.clearInterFunc();

		// 设置初始数据
		this.xData = JSON.parse(JSON.stringify(this.echartDataObj[this.selectType]));
		// 横坐标数据
		const xData = this.xData;

		// 柱状图数据格式
		let xSeries = [];
		xData[0].forEach((i, id) => {
			if (id) xSeries.push({ type: 'bar', barWidth: this.fontSize(this.barWidth / 100), barGap: 0 })
		});
		// console.log('----- 柱状图 series 数据', xSeries, xData)

		// y轴单位的 基数
		let yNameObj = {
			1000: '千',
			10000: '万',
			100000: '十万',
		};

		// 重新设置数据的倍率大小
		let resizeRate: number = 1;
		let max: string | number = 0; // 获取最大数值，以此来显示y轴刻度尺（因为超过千头，数字就很长了，影响数据显示，优化显示问题）
		xData.forEach((i, id) => {
			// 看xData的数据格式，第一个是分类名称，不是数据，所以需要跳过
			if (id) {
				i.forEach((sub, sid) => {
					if (sid && sub > max) max = sub;
				})
			}
		});
		// console.log('当前表格最大就是：', max);

		// 数据最大值的长度，用来判断设置y轴数字的偏移量
		let maxLen = max.toString().length;
		// console.log('maxLen', max, maxLen)

		// setRate();		// 看需求是否需要把30000头 转成 3万头，到时候放开
		// 因为设置了minInterval最小增量，所以导致自动转换单位的时候，只会有0，1万，2万这种情况，导致不能自适应高度
		// console.log('重新设置后的数据： ', xData)

		function setRate(): void {
			switch(maxLen) {
				case 6:
					// console.log('设置缩小倍率为十万：100000');
					resizeRate = 100000;
					resizeXdata();
					break;
				case 5:
					// console.log('设置缩小倍率为万：10000');
					resizeRate = 10000;
					resizeXdata();
					break;
				case 4:
					// console.log('设置缩小倍率为千：1000');
					resizeRate = 1000;
					resizeXdata();
					break;
			}
		}

		// 按照当前缩放倍率，重新设置数据
		function resizeXdata() {
			xData.forEach((i, id) => {
				if (id) {
					i.forEach((sub, sid) => {
						if (sid) {
							// console.log('----', sub, resizeRate)
							i[sid] = Number(sub) / resizeRate
						}
					})
				}
			})
		}

		// grid对象的 图表位置 属性:用来设置echart图标整体大小位置的

		// let left = this.fontSize((25 + (maxLen > 3 ? (maxLen - 3) * 22 : 10)) / 100);
		let left = this.fontSize(0.68);
		let right = this.fontSize(0.1);
		// let top = this.changeDate ? 40 : 20 + this.gridTop; // 如果没有切换日期的图表，高度可以调高一点
		let top = this.fontSize((40 + this.gridTop)/100); // 如果没有切换日期的图表，高度可以调高一点
		let bottom = this.fontSize((this.changeDate ? 48 : 28) / 100);


		let startValue = this.startValue; // 窗口起始数值index
		let endValue = this.endValue;   // 窗口结束数值index(最大显示几个)


		// 之前的操作都是一系列数据的过滤设置
		// console.log('------refs container', this.myChart)
		let option;

		let yName = this.yName;

		option = {
			color: this.barColor,
			legend: {
				// 控制 顶部标签 开关，做额外的处理，不然默认的非常占空间
				show: this.showLegend,
				zlevel: -1,
				color: 'white',
				right: this.legendRight ? this.fontSize(this.legendRight/100) : this.routerPath ? this.fontSize(0.15) : this.fontSize(0.2),
				top: this.legendTop ? this.fontSize(this.legendTop/100) : this.routerPath ? this.fontSize(0.15) : 0, // 判断是否有详情按钮，有的话legend图例往下挪动
				width: this.legendWidth,  // 这个很重要，用来控制legend换行的，超过了指定的宽度，图例就会换行
				itemHeight: this.fontSize(0.1), // 修改icon图形大小
				itemWidth: this.fontSize(0.1),
				itemGap: this.fontSize(0.05), // 修改间距
				// align: 'right',
				textStyle: {
					fontSize: this.fontSize(0.14),
					color: "#fff",
					padding: [0, 0, 0, 0], // 修改文字和图标距离
				},
			},
			// 设置初始能显示几个柱状图
			dataZoom: [
				{ // 第一个 dataZoom 组件
					type: 'inside',
					disable: true,
					xAxisIndex: 0, // 表示这个 dataZoom 组件控制 第一个 xAxis
					startValue: startValue, // 数据窗口范围的起始数值index
					endValue: endValue, // 数据窗口范围的结束数值index
					maxValueSpan: endValue,				// maxValueSpan 和 minValueSpan 相同，表示x轴固定显示几条数据，缩放也不会改变
					minValueSpan: endValue
				},
			],
			// 鼠标悬浮到柱状图指标上面 显示对应的数据
			tooltip: {
				trigger: 'axis',
				axisPointer: {
					type: 'none', // 悬浮上去，柱状图会有阴影样式的设置
				},
				textStyle: {
					fontSize: this.fontSize(0.12),		// 鼠标悬浮显示文字字体大小
				},
				// 鼠标移动到柱状图上面的显示界面：因为缩小了倍率，所以需要放到回到原数据大小
				formatter: (params) => {
					// console.log('tooltips params', params)
					let str = '' + params[0].name + '<br/>';
					params.forEach((item, idx) => {
						// item.value[idx + 1] : value是个数组，第一个参数是地区，后面的才是数据，所以需要+1
						str += `${item.marker} ${item.seriesName}:&nbsp;&nbsp;&nbsp;${Math.floor((isNaN(item.value[item.seriesIndex + 1]) ? 0 : item.value[item.seriesIndex + 1]) * resizeRate)}${yName}`
						str += idx === params.length -1? '': '<br/>'
					})
					return str
				}
			},
			grid: {
				// 设置图表填充空间的位置，避免浪费空间
				left,
				right,
				top,
				bottom,
			},
			dataset: {
				// 图表数据
				source: xData,
			},
			xAxis: {
				type: 'category',
				axisLabel: {
					color: "#93B5FF",
					interval: 0,
					fontSize: this.fontSize(0.12),
					formatter: (value) => {
						// 设置x轴 名称过长 问题：超过4个字省略 ...
						if (value.length > 4) {
							return value.substr(0, 3) + "...";
						} else {
							return value.substr(0, 4);
						}
					},
				},
				axisTick: {			// 坐标轴线的刻度
					show: false
				},
				axisLine: {			// x轴的坐标轴线
					show: true,
					lineStyle: {
						color: '#1F63A3'
					}
				},
			},
			yAxis: {
				name: `单位 (${yNameObj[resizeRate] || ''}${yName})`,
				nameGap: this.fontSize(0.1),
				// alignTicks: false,
				splitNumber: 4,
				type: "value",
				scale: true,
				minInterval: 1,		// 纵坐标最小增量为1，避免显示小数
				splitLine: {
					// show 设置y轴背景的刻度线为 隐藏
					show: false,
					lineStyle: {
						color: "rgba(82, 47, 47, 1)"
					}
				},
				nameTextStyle: {
					fontSize: this.fontSize(0.12),		// 单位(头) 的字体大小
					align: 'right'
				},
				axisLabel: {
					fontSize: this.fontSize(0.12),		// y轴刻度尺 （100，200，300） 的字体大小
				},
				min: 0,
				// min: (value) => {
				// 	// y轴最小高度自适应，让最小的数值为y轴起点
				// 	// return value.min
				// },
				// // 本来是用max的，但是因为我把纵坐标刻度过滤了一遍，导致纵向移动的时候
				// // 偶尔会把最高的那个数值展现成真实的数据
				// max: (value) => {
				// 	// console.log(value)
				// 	// y轴高度自适应，让最高的y轴为最高，避免多余的空间浪费
				// 	return value.max
				// }
				axisTick: {			// 坐标轴线的刻度
					show: true,
					inside: true, // 是否朝内，默认朝外
					lineStyle: {
						color: '#133C7B'
					}
				},
				axisLine: {			// y轴的坐标轴线
					show: true,
					lineStyle: {
						color: '#1F63A3'
					}
				}
			},
			textStyle: {
				color: '#fff',
				// fontSize: 8,
			},
			series: xSeries
		};

		option && this.myChart.setOption(option, {
			notMerge: true	// 这个参数是用来设置重新渲染图表的，不然会出现数据不更新的问题
		});

		// 如果用户设置了需要自动轮播
		if (this.autoPlay) {
			let _that = this;
			// let _nowStart = 0;
			// let _nowEnd = 0;

			// 定时器效果自适应: 根据参数不同,进行不同效果的动画
			function auto() {

				// /* 这段代码是最开始的需求: 
					// 1:如果能放下,就轮播
					// 2:不能放下,就滑动窗口,但是不需要轮播
					// console.log('auto', xData);
					// 暂时不进第一个判断,后续如果改需求，可以参考这段效果
					if (false && _that.barMode === 'mode2' && endValue >= xData.length - 2) {
						// 此时表示，目前的echarts图表能放下所有的x轴数据，因此不需要滑动显示
						// 设置 tooltip 轮播图
						let index = 0;
						// setTimeout(() => {
							_that.intervalNumId = setInterval(() => {
								if (_that.stopInterval) {
									// console.log('目前鼠标是移入状态，不能执行定时器');
									_that.clearInterFunc();
									throw new Error('不应该执行定时器');
								}
								_that.checkInteFunc();
								_that.myChart.dispatchAction({
									type: 'showTip',
									seriesIndex: 0,
									dataIndex: index
								});
								index++;
								if(index > xData.length - 2) {
									index = 0;
								}
							}, 3000)
							_that.intervalNumArr.push(_that.intervalNumId)
						// }, _that.delayTime)
					} else if (_that.barMode === 'mode2') {
						// 设置定时器, 每隔2s判断是否滚动到末尾, 是则重置为初始状态, 否则向前滚动一位

						// setTimeout(() => {
							_that.intervalNumId = setInterval(() => {
								if (_that.stopInterval) {
									// console.log('目前鼠标是移入状态，不能执行定时器');
									_that.clearInterFunc();
									throw new Error('不应该执行定时器');
								}
								_that.checkInteFunc();
								let slide = option.dataZoom[0];
								if (slide.endValue == xData.length - 2) {
									slide.endValue = endValue;
									slide.startValue = startValue;
								} else {
									slide.endValue = slide.endValue + 1;
									slide.startValue = slide.startValue + 1;
								}
								// console.log('---option：', option)
								_that.myChart.setOption(option)
							}, 3000)
							_that.intervalNumArr.push(_that.intervalNumId)
						// },  _that.delayTime);
					}
				// */


				// /*
				// 设置定时器
				// 情景一: 如果当前页面能够直接放下所有数据，那么就只需要进行tooltip的轮播
				// 情景二: 如果放不下，先在当前展示数据上进行tooltip的轮播，播完了，往右滑动下一组数据，再次轮播
				// 数据总长度 xData.length - 1
				// 展示长度 endValue + 1
				if (_that.barMode === 'mode3' && (endValue >= (xData.length - 2))) {
					// console.log('我进了情景1的定时器');
					// 情景一
					// 此时表示，目前的echarts图表能放下所有的x轴数据，因此不需要滑动显示
					// 设置 tooltip 轮播图
					let index = 0;
					// setTimeout(() => {
						_that.intervalNumId = setInterval(() => {
							if (_that.stopInterval) {
								// console.log('目前鼠标是移入状态，不能执行定时器');
								_that.clearInterFunc();
								throw new Error('不应该执行定时器');
							}
							_that.checkInteFunc();
							_that.myChart.dispatchAction({
								type: 'showTip',
								seriesIndex: 0,
								dataIndex: index
							});
							// console.log('------tooltip滑动了', index);
							index++;
							if(index > xData.length - 2) {
								index = 0;
							}
						}, 3000);

						_that.intervalNumArr.push(_that.intervalNumId)
						// console.log('这个定时器的编号是: ',_that.intervalNumArr);
					// }, _that.delayTime)
				} else if (_that.barMode === 'mode3') {
					// console.log('我进了情景2的定时器, 一次放不下');
					let max = endValue;
					// 最多需要的循环次数
					let loop = Math.ceil((xData.length - 1) / (max + 1));
					let start = 0;	// 滑动窗口第几轮
					let index = 0;	// tooltip轮播起始位置
					// setTimeout(() => {

						intervalSelf();

						// 循环器封装一下,方便自调用
						function intervalSelf(): void {
							_that.intervalNumId = setInterval(() => {
								if (_that.stopInterval) {
									// console.log('目前鼠标是移入状态，不能执行定时器');
									_that.clearInterFunc();
									throw new Error('不应该执行定时器');
								}
								_that.checkInteFunc();
								_that.myChart.dispatchAction({
									type: 'showTip',
									seriesIndex: 0,
									dataIndex: index
								});
								
								// console.log('------轮播的 tooltip滑动了', index);
								index++;

								// 滑动窗口
								const slide = option.dataZoom[0];

								if (index === xData.length || _that.needInitInterval) {
									_that.needInitInterval = false;
									// console.log('------------滑动到了最后的数据,tooltip也轮播到最后了,需要全部初始化,重新在开始轮播了');
									_that.clearInterFunc();		// 清除定时器

									index = 0;
									start = 0;
									slide.endValue = max;
									slide.startValue = 0;
									_that.myChart.setOption(option)
									intervalSelf();
									return;
								}

								if(index > slide.endValue + 1 && start <= loop) {
									// console.log('-----------我此轮播到头了,开始下一次轮播', start, loop);
									start += 1;
									_that.clearInterFunc();		// 先清空定时器,然后做一些数据处理

									// console.log('-----start', slide.startValue, slide.endValue, max, start)
									// 设置下一轮滑动窗口的起始结束边界
									slide.endValue = slide.endValue + max + 1;
									slide.startValue = slide.startValue + max + 1;
									_that.myChart.setOption(option);
									index--;		// 这里index--, 是为了避免切换窗口的时候,漏了当前窗口的第一个轮播

									intervalSelf();
								}
							}, 3000);

							_that.intervalNumArr.push(_that.intervalNumId)
							// console.log('这个定时器的编号是: ',_that.intervalNumArr);
						}
					// }, _that.delayTime)
				}
				// */
			}

			// echart鼠标移入移出，停止/重新开始 定时器轮播效果
			const dom = document.getElementsByClassName('boxClass' + this.className);

			function mouseenter () {
				// console.log('---------mouseover事件, 清除定时器, 编号: ', _that.intervalNumArr);
				_that.clearInterFunc();
				_that.stopInterval = true;
			}

			function mouseleave() {
				// console.log('---------mouseout事件, 打开定时器，自动轮播');
				_that.needInitInterval = true;
				_that.needAuto = true;
				_that.stopInterval = false;
				auto();
			}

			// 需要先把之前的监听事件清除，但是因为方法每次调用生产的地址都不同，因此要在外面变量存着上一次的地址
			dom[0].removeEventListener('mouseenter', _that.enterFunc);
			dom[0].removeEventListener('mouseleave', _that.leaveFunc);


			_that.enterFunc = mouseenter;
			_that.leaveFunc = mouseleave;

			setTimeout(() => {
				dom[0].addEventListener('mouseenter', mouseenter);
				dom[0].addEventListener('mouseleave', mouseleave);
			}, 10);

			
			// refs 的dom节点悬浮有问题，放在月/周选择div上面，竟然算鼠标移出了dom，因此使用js原生dom
			// this.chartDom.onmouseover = function () {
			// 	// 本来使用echarts的事件，但是它那个需要移动到有颜色的柱子上才会触发，这样不行
			// 	// 需要显示tooltip 就触发，没办法，只能移入整个dom结构就触发
			// 	console.log('---------mouseover事件, 清除定时器, 编号: ', _that.intervalNumArr);
			// 	_that.clearInterFunc();
			// }

			// this.chartDom.onmouseout = function () {
			// 	console.log('---------mouseout事件, 打开定时器，自动轮播');
			// 	_that.needInitInterval = true;
			// 	auto();
			// }

			// 点击切换 周/月 的时候，用户正在操作图表，不要自动滚动，离开echart在自动
			if (this.needAuto) {
				auto();
			}

			// this.firstSetTime = false;
		}
	}
}
